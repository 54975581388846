import React, { useState } from 'react'
import { FormGroup, Label } from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { InputPropsType } from '../types'
import CustomField from './CustomField'

export interface DateFieldProps {
    inputFormat?: string
    outputFormat?: string
}

const DateField: React.FC<InputPropsType & DateFieldProps> = ({
    id,
    inputFormat,
    label,
    name,
    outputFormat,
    onChange,
    value
}) => {
    const initialDate = value
        ? moment(value, inputFormat).format(outputFormat)
        : moment().format(outputFormat)
    const [currentValue, setCurrentValue] = useState<string>(initialDate)

    const handleChange = (date: Date) => {
        const selectedDate = moment(date)
        const newDate = selectedDate.format(outputFormat)
        setCurrentValue(newDate)
        if (onChange && typeof onChange === 'function') {
            onChange({
                target: { name: name || '', value: newDate }
            } as React.ChangeEvent<HTMLInputElement>)
        }
    }

    return (
        <FormGroup>
            {Boolean(label) && (
                <Label className="font-semibold" for={id}>
                    {label}
                </Label>
            )}
            <div className="w-full">
                <DatePicker
                    className="w-full"
                    wrapperClassName="w-full"
                    value={currentValue}
                    customInput={<CustomField />}
                    onChange={handleChange}
                />
            </div>
        </FormGroup>
    )
}

DateField.defaultProps = {
    inputFormat: 'YYYY/MM/DD',
    outputFormat: 'YYYY/MM/DD'
}

export default DateField
