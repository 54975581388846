import React from 'react'
import HeaderColRenderer from './HeaderColRenderer'
import { useGridContext } from '../hooks'
import { ControlCol, Input } from './styled'
interface HeaderRendererProps {
    withActions: boolean
}

/**
 *  Renders the headers for the grid, there is nothing special, except for the
 * validation, if the grid includes actions, this renderer add an extra column at the end.
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const HeaderRenderer: React.FC<HeaderRendererProps> = ({ withActions }) => {
    const { gridId, allowSelect, headerNames, sortNames, sorts, headers } = useGridContext()
    return (
        <thead>
            <tr>
                {allowSelect && (
                    <ControlCol>
                        <Input className="form-check-input" type="checkbox" id="checkbox1" />
                    </ControlCol>
                )}
                {headerNames.map((headerName, key) => (
                    <HeaderColRenderer
                        key={`${gridId}-${key}`}
                        name={headerName}
                        headerExtraConfig={headers[headerName]}
                        withSort={sortNames.includes(headerName)}
                        sortValue={sorts[headerName]}
                    />
                ))}
                {withActions && <th>&nbsp;</th>}
            </tr>
        </thead>
    )
}

export default HeaderRenderer
