import React, { useState } from 'react'
import { InputPropsType } from '../types'
import { defaultInputProps } from '../defaults'
import TextField from '../text-field'
import { Button } from '../../buttons'
import { VariantType } from '../../buttons/button/types'
import { Icon } from '../../ui'

export interface PasswordFieldProps {
    /** Override the button variant */
    buttonVariant?: VariantType
}

/**
 * This component Allows to render a input field with password behavior
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const PasswordField: React.FC<InputPropsType & PasswordFieldProps> = (props) => {
    delete props.type
    const [visible, setVisible] = useState<boolean>(false)
    const toggleVisible = () => setVisible(!visible)

    return (
        <TextField
            addOn={
                <Button onClick={toggleVisible} variant={props.buttonVariant}>
                    <Icon type={visible ? 'eye-slash' : 'eye'} />
                </Button>
            }
            type={visible ? 'text' : 'password'}
            {...props}
        />
    )
}

PasswordField.defaultProps = {
    ...defaultInputProps,
    buttonVariant: 'inverse'
}

export default PasswordField
