import React from 'react'
import ReactJsonPrint from 'react-json-print'
import { useGridContext } from '../hooks'

/**
 * Renders debug options for the grid
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns React.FC
 */
const GridDebugger: React.FC = () => {
    const { gridId, headers, meta, filtersConfig, filterValues, sorts, loading, viewFilters } =
        useGridContext()
    return (
        <ReactJsonPrint
            expanded
            dataObject={{
                filterValues,
                filtersConfig,
                gridId,
                headers,
                loading,
                meta,
                sorts,
                viewFilters
            }}
        />
    )
}

export default GridDebugger
