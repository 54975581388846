/**
 * Function to check and trigger a callback only when the Enter key is pressed
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param e
 * @param callBack
 */
export const listenForEnterKey = (e: React.KeyboardEvent<HTMLInputElement>, callBack: any) => {
    const { key } = e
    if (key === 'Enter') {
        callBack()
    }
}
