import React from 'react'
import { IconType } from '@alejandro.devop/bt-types'
import { HeaderCol, HeaderSortIcon, HeaderText, HeaderContainer } from './styled'
import { useGridContext } from '../hooks'
import { HeaderExtraConfigType } from '../types'

interface HeaderColRendererProps {
    /** Name for the giving column */
    name: string
    withSort?: boolean
    sortValue?: 0 | 1 | -1
    headerExtraConfig?: HeaderExtraConfigType
}

/**
 * Renders a single column for the header, renders the icon if the header requires sort
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const HeaderColRenderer: React.FC<HeaderColRendererProps> = ({
    name,
    withSort,
    sortValue,
    headerExtraConfig
}) => {
    const { label, hide } = headerExtraConfig || {}
    const { headerTextProcessor, onChangeSort } = useGridContext()
    const getIcon = (): IconType => {
        if (sortValue === 1) {
            return 'sort-up'
        } else if (sortValue === -1) {
            return 'sort-down'
        }
        return 'sort'
    }
    const props = withSort
        ? { onClick: () => onChangeSort(name) }
        : { style: { cursor: 'default' } }
    return (
        <HeaderCol {...props}>
            <HeaderContainer>
                {!hide && (
                    <HeaderText>
                        {headerTextProcessor ? headerTextProcessor(label || name) : label || name}
                    </HeaderText>
                )}

                {withSort && <HeaderSortIcon type={getIcon()} disabled={sortValue === 0} />}
            </HeaderContainer>
        </HeaderCol>
    )
}

export default HeaderColRenderer
