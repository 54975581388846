import React, { useState } from 'react'
import { FormGroup, Label } from 'reactstrap'
import 'react-datetime/css/react-datetime.css'
import DateTime from 'react-datetime'
import moment from 'moment'
import { InputPropsType } from '../types'
import CustomField from './CustomField'

export interface TimeFieldProps {
    inputFormat?: string
    outputFormat?: string
    format?: string
}

const TimeField: React.FC<InputPropsType & TimeFieldProps> = ({
    id,
    inputFormat,
    label,
    name,
    outputFormat,
    format,
    onChange,
    value
}) => {
    const initialDate = value ? moment(value, inputFormat) : moment()
    const [currentValue, setCurrentValue] = useState<moment.Moment>(initialDate)

    const handleChange = (data: moment.Moment) => {
        const newValue = data.format(outputFormat)
        setCurrentValue(data)
        if (onChange) {
            onChange({
                target: { name: name || '', value: newValue }
            } as React.ChangeEvent<HTMLInputElement>)
        }
    }

    return (
        <FormGroup>
            {Boolean(label) && (
                <Label className="font-semibold" for={id}>
                    {label}
                </Label>
            )}
            <div>
                <DateTime
                    timeFormat={format}
                    dateFormat={false}
                    onChange={handleChange}
                    value={currentValue}
                    renderInput={({ onClick, onChange, value }) => {
                        return <CustomField onClick={onClick} onChange={onChange} value={value} />
                    }}
                />
            </div>
        </FormGroup>
    )
}

TimeField.defaultProps = {
    inputFormat: 'HH:mm',
    format: 'HH:mm',
    outputFormat: 'HH:mm'
}

export default TimeField
