import {
    CheckBoxField,
    DateField,
    DropDownField,
    EmailField,
    FilePickerField,
    PasswordField,
    RadioField,
    SelectField,
    TextArea,
    TextField,
    TimeField
} from '@alejandro.devop/bt-components'

/**
 * This map is used to configure the grid filter fields
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
const fieldsMap = {
    checkbox: CheckBoxField,
    date: DateField,
    dropdown: DropDownField,
    email: EmailField,
    file: FilePickerField,
    password: PasswordField,
    radio: RadioField,
    select: SelectField,
    textarea: TextArea,
    text: TextField,
    time: TimeField
}

export default fieldsMap
