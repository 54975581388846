import styled from 'styled-components'
import { Icon } from '@alejandro.devop/bt-components'

export const HeaderCol = styled.th`
    cursor: pointer;
`

export const ControlCol = styled.th`
    width: 30px;
    text-align: center;
`

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: 'row';
`
export const HeaderText = styled.span`
    flex: 1;
`
export const HeaderSortIcon: any = styled(Icon)`
    opacity: 0.4;
`

export const Input = styled.input`
    margin-left: 15px;
    position: relative;
`
