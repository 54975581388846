import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { InputPropsType } from '../types'

const TextArea: React.FC<InputPropsType> = ({ id, name, label, onChange, value }) => {
    return (
        <FormGroup>
            {Boolean(label) && (
                <Label className="font-semibold" for="exampleText">
                    {label}
                </Label>
            )}
            <Input type="textarea" name={name} onChange={onChange} id={id} value={value} />
        </FormGroup>
    )
}

export default TextArea
