import styled from 'styled-components'

export const ActionLink = styled.a`
    margin: 10px 10px;
`

export const ActionsCol = styled.th`
    width: 1%;
    white-space: nowrap;
`
