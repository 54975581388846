import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Icon } from '@alejandro.devop/bt-components'
import AppliedFilters from './AppliedFilters'
import Animation from 'react-reveal/Slide'
import { useGridContext } from '../hooks'
import RenderFilter from './RenderFilter'

/**
 * Renders structure for the filters used in the grid
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const GridSearch: React.FC<{
    visible?: boolean
}> = ({ visible }) => {
    const { toggleFilters, textProcessor, filtersConfig, clearFilters } = useGridContext()
    const processText = (text: string) => (textProcessor ? textProcessor(text) : text)
    return (
        <>
            <Container fluid className="mt-2">
                <Row>
                    <Col xs="12">
                        <AppliedFilters />
                    </Col>
                </Row>
            </Container>
            {visible && (
                <Animation right collapse when={visible === true}>
                    <Container className="mb-4 bg-white" fluid>
                        <Row>
                            {filtersConfig.map((filter, key) => {
                                return <RenderFilter key={`filter-${key}`} filterConfig={filter} />
                            })}
                        </Row>
                        <Row>
                            <Col xs="12" className="mt-4">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary mr-2"
                                    onClick={clearFilters}
                                >
                                    <Icon type="eraser" />
                                    <span className="ml-2">{processText('clearFilters')}</span>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-white"
                                    onClick={toggleFilters}
                                >
                                    <Icon type="times" />
                                    <span className="ml-2">{processText('closeFilters')}</span>
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </Animation>
            )}
        </>
    )
}

export default GridSearch
