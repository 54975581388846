import React from 'react'
import { Spinner } from 'reactstrap'
import { GridConsumer } from '../context'
import GridOptionsRender from '../grid-options-render/GridOptionsRender'
import DataRenderer from '../data-renderer'
import HeaderRenderer from '../header-render'
import GridLoader from '../grid-loader'
import GridSearchRender from '../grid-search-render'
import GridPaginationRender from '../grid-pagination-render'
import GridDebugger from '../grid-debugger/GridDebugger'
import AppliedSorts from '../applied-sorts'

/**
 * Assembles and renders the grid pieces
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns React.FC
 */
const GridRender: React.FC = () => {
    return (
        <GridConsumer>
            {({ actions, loadingLabel, debug, loading, headerNames, viewFilters }) => (
                <>
                    <div>
                        {debug && <GridDebugger />}

                        <GridOptionsRender />
                        <GridSearchRender visible={viewFilters} />
                        <AppliedSorts />
                        {loading && (
                            <div className="flex flex-row items-center">
                                <Spinner className="mr-3" type="grow" color="primary" size="sm" />
                                {loadingLabel && <span className="text-xs">{loadingLabel}</span>}
                            </div>
                        )}
                        <GridPaginationRender disableGoTo disableStepper disablePager />
                        <table className="table table-striped ">
                            <HeaderRenderer withActions={Boolean(actions)} />
                            {!loading && <DataRenderer />}
                            {loading && (
                                <GridLoader
                                    cols={headerNames.length + (Boolean(actions) ? 1 : 0)}
                                    rows={10}
                                />
                            )}
                        </table>
                        <GridPaginationRender />
                    </div>
                </>
            )}
        </GridConsumer>
    )
}

export default GridRender
