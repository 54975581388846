import React from 'react'
import { IconButton } from '@alejandro.devop/bt-components'
import { Paragraph } from './styled'
import { useGridContext } from '../hooks'

type FilterToDisplayType = { label: string; value: string }

/**
 * Renders the applied filters
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns
 */
const AppliedFilters: React.FC = () => {
    const {
        textProcessor,
        filterValues,
        displayFiltersFormatter,
        headerTextProcessor,
        onChangeFilter
    } = useGridContext()
    const processText = (key: string) => (textProcessor ? textProcessor(key) : key)
    let reducedFilters: FilterToDisplayType[] = Object.keys(filterValues).reduce(
        (filters, currentKey) => {
            if (filterValues[currentKey]) {
                filters.push({ label: currentKey, value: filterValues[currentKey] })
            }
            return filters
        },
        [] as FilterToDisplayType[]
    )
    const processHeaderText = (text: string) =>
        headerTextProcessor ? headerTextProcessor(text) : text

    const handleClearFilter = (key: string) => {
        onChangeFilter(key, '')
    }

    if (displayFiltersFormatter) {
        reducedFilters = displayFiltersFormatter(reducedFilters) as FilterToDisplayType[]
    }
    if (reducedFilters.length === 0) {
        return null
    }
    return (
        <div className="flex flex-row items-center">
            <Paragraph>{processText('appliedFilters')}</Paragraph>
            {reducedFilters.map((filter, key) => (
                <span
                    key={`filter-applied-${key}`}
                    className="badge bg-indigo flex items-center flex-row mr-2"
                >
                    {`${processHeaderText(filter.label)}: ${filter.value}`}{' '}
                    <IconButton
                        onClick={() => handleClearFilter(filter.label)}
                        icon="times"
                        size="xs"
                    />
                </span>
            ))}
        </div>
    )
}

export default AppliedFilters
