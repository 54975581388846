import React from 'react'
import { Col } from 'reactstrap'
import { InputPropsType } from '@alejandro.devop/bt-components'
import { FilterOptionsType } from '../types'
import { useGridContext } from '../hooks'
import fieldsMap from '../fields-map'

// let timerForInputChange: any

/**
 * Renders a single filter item and resolves it depending on the type
 * @param props
 * @returns
 */
const RenderFilter: React.FC<{ filterConfig: { key: string } & FilterOptionsType }> = ({
    filterConfig
}) => {
    const { headerTextProcessor, filterValues, onChangeFilter } = useGridContext()
    const processText = (text: string) => (headerTextProcessor ? headerTextProcessor(text) : text)
    const { label, key, name, type, selectOptions, preProcessFilterValue } = filterConfig
    let FieldComponent: any = fieldsMap.text
    if (type) {
        FieldComponent = fieldsMap[type] || fieldsMap.text
    }
    const keyToChange = name || key
    const currentValue = filterValues[keyToChange]

    const handleChange = ({ target }: { target: { value: any; name: string } }) => {
        const { name, value } = target
        onChangeFilter(name, preProcessFilterValue ? preProcessFilterValue(value) : value)
    }

    let componentProps: InputPropsType & any = {
        placeholder: processText(key),
        label,
        name: keyToChange,
        onChange: handleChange,
        value: currentValue
    }

    /**
     * If the type is select then there are some special props that maybe we will need to pre-process
     */
    if (type === 'select') {
        const {
            advanced,
            ajax,
            columnsMapping,
            requestConfig,
            disableGroup,
            empty,
            multiple,
            url,
            value
        } = selectOptions || {}
        componentProps = {
            ...componentProps,
            requestConfig,
            advanced,
            ajax,
            columnsMapping,
            disableGroup,
            empty,
            multiple,
            url,
            value: value as any
        }
    }

    return (
        <Col xs="12" sm="6" md="3" className="mt-2">
            <div className=" input-group-sm">
                <FieldComponent {...componentProps} />
            </div>
        </Col>
    )
}

export default RenderFilter
