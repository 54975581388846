import React, { useState, useEffect } from 'react'
import { Icon } from '@alejandro.devop/bt-components'
import { PaginationLink, PaginationItem } from 'reactstrap'
import { Paragraph, Pagination, Input } from './styled'
import { useGridContext } from '../hooks'
import { isEmpty } from 'lodash'
import { listenForEnterKey } from '../utils/events'

interface GridPaginationDisplayProps {
    /** Value for the page marked as current */
    activePage: number
    disableGoTo?: boolean
    disableStepper?: boolean
    disablePager?: boolean
    /** Function to change the current page to the the very first page. */
    goToFirst: (e: React.MouseEvent) => void
    /** Function to change the current page to the last available page. */
    goToLast: (e: React.MouseEvent) => void
    /** Function to change the current page to the given number */
    handleClickOnNumber: (e: React.MouseEvent, n: number) => void
    /** Function to apply the go to value and force a new server request */
    handleApplyGoTo: (n: number) => void
    /** Function to change the current page to the next value */
    handleNext: (e: React.MouseEvent) => void
    /** Function to change the current page to the previews value */
    handlePrev: (e: React.MouseEvent) => void
    /** Limit for the records per page */
    limit: number
    /** A calculated point between the first page to be displayed and the last page to be displayed */
    middlePoint: number
    /** A function to apply the records per page entered by the user and force a new server request */
    onApplyRecordsPerPage: () => void
    /** Function to change the given control for the records per page */
    onChangeRecordsPerPage: (newValue: any) => void
    /** Numbers array with the pages to be rendered */
    pagesToRender: number[]
    /** Current value for the records per page */
    recordsPerPageControl: number
    /** If the item displaying "..." symbol should be displayed */
    shouldSkipPages: any
    /** Step for the records per page */
    step?: number
}

/**
 * Timer used to delay the apply action for the goToPage button
 */
let timerForApplyGoTo: any = null

/**
 * Only handles the pagination render structure, has managed states for the inputs, but
 * it transfer the entered data right to it's parent controller.
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const GridPaginationDisplay: React.FC<GridPaginationDisplayProps> = ({
    pagesToRender,
    disableGoTo,
    disablePager,
    disableStepper,
    goToFirst,
    goToLast,
    handleNext,
    handlePrev,
    recordsPerPageControl,
    onChangeRecordsPerPage,
    onApplyRecordsPerPage,
    activePage,
    limit,
    handleClickOnNumber,
    shouldSkipPages,
    middlePoint,
    handleApplyGoTo,
    step = 10
}) => {
    const { textProcessor, meta } = useGridContext()
    const { totalRecords, totalPages } = meta
    const [currentGoToPage, setCurrentGoToPage] = useState<string | number>('')
    const [enabledEnterStroke, setEnableEnterStroke] = useState<boolean>(false)
    const processText = (key: string) => (textProcessor ? textProcessor(key) : key)

    /**
     * Controls the value change for the records per page state
     * @param event
     */
    const handleChangeRecordsPerpage = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target
        if (!isNaN(value as any)) {
            onChangeRecordsPerPage(parseInt(value, 10))
        }
    }

    /**
     * Control for the add records per page button (When the user presses the add button)
     */
    const handleUpRecordsPerPage = () => {
        let newValue = recordsPerPageControl + step
        if (newValue > 100) {
            newValue = 100
        }
        onChangeRecordsPerPage(newValue)
    }
    /**
     * Control for the substract records per apge button (When the user presses the substract button)
     */
    const handleDownRecordsPerPage = () => {
        let newValue = recordsPerPageControl - step
        if (newValue < step) {
            newValue = step
        }
        onChangeRecordsPerPage(newValue)
    }

    /**
     * Controls the value change for the input "go to page"
     */
    const handleChangeGoTo = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target
        // If the user enters a letter or a non number we dont want to to proceed with the go to action
        if (!isNaN(value as any) && parseInt(value, 10) >= 1) {
            setCurrentGoToPage(value)
            clearTimeout(timerForApplyGoTo)
            // this delays allows to stop the server request call action.
            timerForApplyGoTo = setTimeout(() => {
                if (parseInt(value as any, 10) > totalPages) {
                    setCurrentGoToPage(totalPages)
                }
                setEnableEnterStroke(true)
            }, 200)
        } else {
            setCurrentGoToPage('')
        }
    }

    /**
     * A totally different action for the apply (Go to page), allows us to reuse the code
     * and attach this behaviour to the enter action
     */
    const handleApply = () => {
        handleApplyGoTo(parseInt(currentGoToPage as any, 10))
        setCurrentGoToPage('')
    }

    useEffect(() => {
        // We want to make sure we set free the resources for this timer.
        return () => clearTimeout(timerForApplyGoTo)
    }, [])

    return (
        <div className="flex flex-1 items-end justify-end">
            <div className=" mt-6 mb-0 flex flex-column items-start flex-1">
                <Paragraph>
                    <Icon type="sort-amount-up" />
                    <span className="ml-2">
                        {textProcessor
                            ? textProcessor('totalRecords', {
                                  key: 'records',
                                  value: totalRecords
                              })
                            : 'records'}
                    </span>
                </Paragraph>
                {!disablePager && (
                    <Pagination className="mr-12 mb-0 pb-0" size="sm">
                        {activePage > 1 && (
                            <>
                                <PaginationItem disabled={activePage === 1}>
                                    <PaginationLink onClick={goToFirst} href="#">
                                        {processText('first')}
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={activePage === 1}>
                                    <PaginationLink onClick={handlePrev} href="#">
                                        {processText('previous')}
                                    </PaginationLink>
                                </PaginationItem>
                            </>
                        )}
                        {pagesToRender.map((page) => (
                            <PaginationItem
                                key={`pagination-item-${page}`}
                                active={activePage === page}
                            >
                                <PaginationLink
                                    href="#"
                                    onClick={(e) => handleClickOnNumber(e, page)}
                                >
                                    {page}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        {shouldSkipPages && activePage < totalPages - (limit - middlePoint) && (
                            <>
                                <PaginationItem disabled>
                                    <PaginationLink href="#">...</PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink
                                        onClick={(e) => handleClickOnNumber(e, totalPages)}
                                        href="#"
                                    >
                                        {totalPages}
                                    </PaginationLink>
                                </PaginationItem>
                            </>
                        )}
                        {activePage < totalPages && (
                            <>
                                <PaginationItem disabled={activePage === totalPages}>
                                    <PaginationLink onClick={handleNext} href="#">
                                        {processText('next')}
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem disabled={activePage === totalPages}>
                                    <PaginationLink onClick={goToLast} href="#">
                                        {processText('last')}
                                    </PaginationLink>
                                </PaginationItem>
                            </>
                        )}
                    </Pagination>
                )}
            </div>
            {!disableStepper && (
                <div className="mb-2 mr-8">
                    <Paragraph>
                        {textProcessor ? textProcessor('recordsPerPage') : 'Records per page'}
                    </Paragraph>
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <button
                                className="btn btn-white btn-sm"
                                onClick={() => handleDownRecordsPerPage()}
                            >
                                <Icon type="chevron-down" />
                            </button>
                        </div>
                        <Input
                            type="number"
                            className="form-control"
                            placeholder="Records per page"
                            onChange={handleChangeRecordsPerpage}
                            onKeyUp={(e) => listenForEnterKey(e, () => {})}
                            readOnly
                            max={100}
                            value={recordsPerPageControl}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-white btn-sm"
                                onClick={() => handleUpRecordsPerPage()}
                            >
                                <Icon type="chevron-up" />
                            </button>
                        </div>
                        <div className="input-group-append">
                            <button
                                className="btn btn-white btn-sm"
                                onClick={() => onApplyRecordsPerPage()}
                            >
                                <span className="mr-2">
                                    {textProcessor ? textProcessor('apply') : 'Apply'}
                                </span>
                                <Icon type="check" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {!disableGoTo && (
                <div className="mb-2">
                    <Paragraph>
                        {textProcessor
                            ? textProcessor('totalPages', { key: 'pages', value: totalPages })
                            : 'Total pages'}
                    </Paragraph>
                    <div className="input-group input-group-sm">
                        <Input
                            type="number"
                            className="form-control"
                            placeholder={textProcessor ? textProcessor('gotoPage') : 'Go to page'}
                            value={currentGoToPage}
                            onChange={handleChangeGoTo}
                            onKeyUp={(e) =>
                                listenForEnterKey(e, () => {
                                    if (enabledEnterStroke) {
                                        handleApply()
                                    }
                                })
                            }
                            onKeyDown={(e) => {
                                // Using this we restrict the enter press util the
                                // timeout validates if the entered value is correct
                                if (e.key !== 'Enter') {
                                    setEnableEnterStroke(false)
                                }
                            }}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary btn-sm"
                                disabled={isEmpty(currentGoToPage) || !enabledEnterStroke}
                                onClick={handleApply}
                            >
                                {textProcessor ? textProcessor('go') : 'Go'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

GridPaginationDisplay.defaultProps = {
    step: 10
}

export default GridPaginationDisplay
