import React from 'react'
import { IconType } from '@alejandro.devop/bt-types'
import { IconSizeType } from './types'

export interface IconProps {
    /** Add class names to the icon */
    className?: string
    /** Where to take the icon set */
    iconSet?: 'fas' | 'fa'
    /** Sizes for the icon to render */
    size?: IconSizeType
    /** Available icons for this theme */
    type: IconType
}

const Icon: React.FC<IconProps> = ({ className, type, iconSet }) => {
    return <i className={`${iconSet} fa-${type} ${className}`} />
}

Icon.defaultProps = {
    iconSet: 'fa'
}

export default Icon
