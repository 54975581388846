import React from 'react'
import { GridContextType } from '../types'

export const GridContext = React.createContext<GridContextType>({
    headers: {},
    fields: {},
    headerNames: [],
    sorts: {},
    sortNames: [],
    onChangeSort: () => null,
    data: [],
    gridId: 'grid',
    allowSelect: false,
    clearFilters: () => null,
    resetSorts: () => null,
    viewFilters: false,
    toggleFilters: () => null,
    onChangeFilter: () => null,
    filtersConfig: [],
    onChangeRecordsPerpage: () => null,
    filterValues: {},
    toggleSettings: () => null,
    openSettings: false,
    toggleVisibility: () => null,
    meta: {
        totalRecords: 0,
        totalPages: 0,
        currentPage: 0,
        nextPage: 0,
        prevPage: 0,
        recordsPerPage: 0
    },
    applyGoToPage: () => null,
    selectedRecords: [],
    handleChangePage: () => null
})

export const GridProvider = GridContext.Provider
export const GridConsumer = GridContext.Consumer
