import React from 'react'
import { ActionType } from '../types'
import { ActionLink, ActionsCol } from './styled'
import { useGridContext } from '../hooks'

interface ActionsRenderProps {
    actions: ActionType[]
    rowRecord: any
}

/**
 * Renders the grid options per row
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const ActionsRender: React.FC<ActionsRenderProps> = ({ actions, rowRecord }) => {
    const { onAcctionCalled } = useGridContext()
    return (
        <ActionsCol>
            {actions.map((action, key) => (
                <ActionLink
                    key={`action-key-${key}`}
                    onClick={() => (onAcctionCalled ? onAcctionCalled(action.id, rowRecord) : null)}
                >
                    <i className={`fa fa-${action.icon}`}></i>
                </ActionLink>
            ))}
        </ActionsCol>
    )
}

export default ActionsRender
