import React from 'react'
import { Paragraph } from './styled'
import { useGridContext } from '../hooks'
import { IconButton } from '@alejandro.devop/bt-components'

type AppliedSortType = { label: string; value: string; key: string }

/**
 * Renders the applied sorts for the grid
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns React.FC
 */
const AppliedSorts: React.FC = () => {
    const { textProcessor, sorts, headerTextProcessor, resetSorts } = useGridContext()
    const processHeaderText = (text: string) =>
        headerTextProcessor ? headerTextProcessor(text) : text
    const processText = (key: string) => (textProcessor ? textProcessor(key) : key)
    const appliedSorts: AppliedSortType[] = Object.keys(sorts).reduce((values, currentKey) => {
        if (sorts[currentKey] !== 0) {
            values.push({
                key: currentKey,
                label: processHeaderText(currentKey),
                value: sorts[currentKey] > 0 ? 'asc' : 'desc'
            })
        }
        return values
    }, [] as AppliedSortType[])
    if (appliedSorts.length === 0) {
        return null
    }
    return (
        <div className="flex flex-row items-center mt-2">
            <Paragraph>{processText('appliedSorts')}:</Paragraph>
            {appliedSorts.map((item, key) => (
                <span
                    className="badge bg-default flex items-center flex-row mr-2"
                    key={`sorts-applied-${key}`}
                >
                    {item.label}: {item.value}
                </span>
            ))}
            <IconButton icon="times" type="info" size="sm" onClick={resetSorts} />
        </div>
    )
}

export default AppliedSorts
