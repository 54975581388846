import React from 'react'
import { FormGroup, Label, CustomInput } from 'reactstrap'
import { InputPropsType } from '../types'

const FilePickerField: React.FC<InputPropsType> = ({ id, label, name, onChange, value }) => {
    return (
        <FormGroup>
            {Boolean(label) && (
                <Label className="font-semibold" for={id}>
                    {label}
                </Label>
            )}
            <CustomInput
                type="file"
                id={id || 'file-field'}
                name={name}
                onChange={onChange}
                value={value}
            />
        </FormGroup>
    )
}

export default FilePickerField
