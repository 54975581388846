import styled from 'styled-components'

export const UndefinedText = styled.span`
    opacity: 0.3;
    font-size: 12px;
`

export const ColWrapper = styled.td`
    width: 30px;
    text-align: center;
`

export const Input = styled.input`
    margin-left: 0;
`
