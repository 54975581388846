import React from 'react'
import ActionsRender from '../actions-renderer'
import { GridHeaderType } from '../types'
import { UndefinedText } from './styled'
import { useGridContext } from '../hooks'
import ColSelectControl from './ColSelectControl'

/**
 * Renders the data for the grid body
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns React.FC
 */
const DataRenderer: React.FC = () => {
    const { actions, data, headerNames, allowSelect, fields, textProcessor } = useGridContext()
    const processText = (text: string) => (textProcessor ? textProcessor(text) : text)
    const resolveDotNotation = (value: string, rowValue: any) => {
        if (value.includes('.')) {
            const parts = value.split('.')
            const reducedValue = parts.reduce((newVal, currentPart) => {
                if (typeof newVal === 'object') {
                    newVal = newVal[currentPart]
                }
                return newVal
            }, rowValue)
            return reducedValue
        }
        return value
    }

    const valueResolver = ({ value }: GridHeaderType, rowValue: any) => {
        if (typeof value === 'string') {
            return resolveDotNotation(value, rowValue)
        } else if (typeof value === 'function') {
            const resolvedValue = value(rowValue)
            return resolvedValue
            // return typeof resolvedValue == 'string' ? resolvedValue : 'invalid value'
        }
        return ''
    }

    return (
        <tbody>
            {data &&
                Array.isArray(data) &&
                data?.map((rowData, rowId) => (
                    <tr key={`row-${rowId}`}>
                        {allowSelect && <ColSelectControl />}
                        {headerNames.map((key) => {
                            const colConfig = fields[key]
                            const resolvedValue = valueResolver(colConfig, rowData)
                            return (
                                <td key={`col-row-${key}`}>
                                    {resolvedValue || (
                                        <UndefinedText>
                                            ( {processText('undefinedValue')} )
                                        </UndefinedText>
                                    )}
                                </td>
                            )
                        })}
                        {actions && <ActionsRender actions={actions} rowRecord={rowData} />}
                    </tr>
                ))}
        </tbody>
    )
}

export default DataRenderer
