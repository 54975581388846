import React, { LegacyRef } from 'react'
import { Input, InputGroup, InputGroupAddon } from 'reactstrap'
import { Icon } from '../../ui'
import { Button } from '../../buttons'

interface CustomDateFieldProps {
    id?: string | number
    name?: string
    onChange?: React.ChangeEventHandler
    onClick?: () => void
    placeholder?: string
    value?: string | number
}

const CustomField: React.FC<CustomDateFieldProps> = React.forwardRef(
    ({ id, name, onChange, onClick, placeholder, value }, ref: LegacyRef<Input>) => {
        return (
            <InputGroup>
                <Input
                    readOnly
                    id={id ? id.toString() : 'custom-input'}
                    ref={ref}
                    className="form-control"
                    name={name}
                    onChange={onChange}
                    onClick={onClick}
                    placeholder={placeholder}
                    value={value}
                    type="text"
                />
                <InputGroupAddon addonType="append">
                    <Button onClick={onClick}>
                        <Icon type="calendar" size="lg" />
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        )
    }
)

export default CustomField
