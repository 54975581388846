import axios, {AxiosResponse} from 'axios'
import {
    RequestConfigWithPayloadType,
    DoRequestResponseType,
} from '../types/HttpClient.types'
import {
    ResolveUrlConfigType,
    MethodType,
    AvailablePropertiesType,
} from '../types/HttpClient.types'
import {
    HeaderType,
    EndpointDefinitionType,
    HttpClientType,
    DataProcessorType,
} from '../types/HttpClient.types'

type DoRequestConfig<PayloadType> = ResolveUrlConfigType &
    RequestConfigWithPayloadType<PayloadType>

class HttpClient<DataIn extends Object = {}, DataOut extends Object = {}> {
    private headers: HeaderType = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    /**
     * Url for the api server
     */
    private readonly server: string
    /**
     * Dictionary with the api endpoints
     */
    private endpoints: EndpointDefinitionType
    private payloadTransform?: (d: any) => any
    /**
     * Callback to be called once the request is done successfuly
     */
    private onRequestDone: ((d: any) => void) | undefined
    /**
     * Function to be colled to process the api given data
     */
    private processData?: DataProcessorType<any, any>

    /**
     * The constructor for the client instance should contain a definition for the data processor
     * @param options HttpClientType
     */
    constructor(options: HttpClientType<DataIn, DataOut>) {
        const {
            server,
            auth,
            onRequestDone,
            dataProcessor,
            headers,
            endpoints,
            payloadTransform,
        } = options
        this.endpoints = endpoints
        this.payloadTransform = payloadTransform
        this.server = server
        this.onRequestDone = onRequestDone
        this.processData = dataProcessor
        if (auth !== undefined) {
            this.addHeaders({Authorization: auth, ...headers})
        }
    }

    /**
     * Allows to add new headers to the client's header object,
     * it will mix (or override) the header keys with the given ones
     * @param headers HeaderType
     */
    public addHeaders(headers: HeaderType) {
        this.headers = {
            ...this.headers,
            ...headers,
        }
    }

    /**
     * Returns the current client headers
     * @returns HeaderType
     */
    public getHeaders(): HeaderType {
        return this.headers
    }

    /**
     * Returns the endpoints dictionary used by the client
     * @returns EndpointDefinitionType
     */
    public getEndpoints(): EndpointDefinitionType {
        return this.endpoints
    }

    /**
     * Returns the server used for the client
     * @returns string
     */
    public getServer(): string {
        return this.server
    }

    /**
     * Allows to set pre-named properties, each property can be treated
     * differently
     * @param properties AvailablePropertiesType
     */
    public setProperties(properties: AvailablePropertiesType) {
        const {headers, auth} = properties
        if (headers) {
            this.addHeaders(headers)
        }
        if (auth) {
            this.addHeaders({
                Authorization: auth,
            })
        }
    }

    /**
     * Returns the current value assignated Authorization header
     * @returns string | undefined
     */
    public getAuthorization(): string | undefined {
        const {Authorization} = this.headers
        return Authorization
    }

    /**
     * Adds necessary params for the url pagination as defined on: https://jsonapi.org/format/#fetching-pagination
     * @param params string[]
     * @param pageSize number
     * @param currentPage number
     * @returns string[]
     */
    public resolvePagination(
        params: string[],
        pageSize?: number,
        currentPage?: number,
    ): string[] {
        if (!currentPage || !pageSize) {
            throw new Error('Page size or current page not defined')
        }
        params.push(`page[size]=${pageSize}`)
        params.push(`page[number]=${currentPage}`)
        return params
    }

    /**
     * Adds the neccesary params to build sorts on url
     * @param params string[]
     * @param sorts string[]
     * @returns string[]
     */
    public resolveSorts(params: string[], sorts?: string[]): string[] {
        if (sorts && sorts.length > 0) {
            params.push(`sort=${sorts.join(',')}`)
        }
        return params
    }

    /**
     * Add the json spect arguments for the filters on the built url
     * @param params string[]
     * @param filters {[k:string]: any}
     * @returns string[]
     */
    public resolveFilters(
        params: string[],
        filters?: {[k: string]: any},
    ): string[] {
        if (filters && typeof filters === 'object') {
            const filterValues = Object.keys(filters).map(
                filterName =>
                    `filter[${filterName}]=${encodeURIComponent(
                        filters[filterName],
                    )}`,
            )
            params = [...params, ...filterValues]
        }
        return params
    }

    public resolveIncludes(params: string[], includes?: string[]): string[] {
        if (!includes) {
            return params
        }
        const includedEntities = includes.map(entity => {
            const [entityName] = entity.split(':')
            return entityName
        })
        params.push(`include=${includedEntities.join(',')}`)
        return params
    }

    /**
     * Searches the dots notation pieces recursively until it resolves the path
     * @param path Dot notation path: users.list
     * @returns
     */
    public composeUrl(path: string) {
        const parts = path.split('.')
        let composedUrl = path
        if (parts.length > 1) {
            /**
             * For this algorithim we will loop through the path parts and search
             * if there is a match inside the endpoints object, the process will be
             * executed recursively until the endpoint is found or every key
             * in endpoints object has been checked
             */
            composedUrl = parts.reduce((newVal: any, currentPart: string) => {
                if (newVal[currentPart]) {
                    newVal = newVal[currentPart]
                }
                return newVal
            }, this.endpoints)
        }
        if (typeof composedUrl !== 'string') {
            return path
        }
        return composedUrl
    }

    /**
     * Loops for every key of the replacements object and replaces every key
     * for it's corresponding value.
     * E.G:
     * # Given url
     * let url = '/my-fency-app/my-controller/:identifier:/:action:'
     * url = resolveReplacements(url, ':', {
     *     'identifier': 1,
     *     'action': 'save'
     * })
     * # It will loop for every key ('identifier', 'action'), and will prepenad and append
     * # the replacement token (':identifier:', ':action:') and will replace found coincidenses
     * # For it's corresponding value
     * # Result:
     * console.log(url)
     * # Output:
     * #:> /my-fency-app/my-controller/1/save
     * @param url
     * @param replaceToken
     * @param replacements
     * @returns
     */
    public resolveReplacements(
        url: string,
        replaceToken?: string,
        replacements?: {[k: string]: any},
    ): string {
        if (typeof replacements === 'object') {
            Object.keys(replacements).forEach(keyToReplace => {
                const replacementExpression = `${replaceToken}${keyToReplace}${replaceToken}`
                url = url.replace(
                    new RegExp(replacementExpression, 'g'),
                    replacements[keyToReplace],
                )
            })
        }
        return url
    }

    /**
     * Extracts the url type (If defined on the endpoints dictionary)
     * @param url string
     * @returns [string, string]
     */
    public getUrlType(url: string): [string, string] {
        let type = ''
        const matches = url.match(new RegExp('(<[a-zA-Z]+>)', 'g'))
        const [match] = matches || []
        type = match?.replace(new RegExp('<|>', 'g'), '')
        url = url.replace(new RegExp('<.*>', 'g'), '')
        return [url, type]
    }

    /**
     * Searchs a given path inside the endpoints object, also builds the filter, sort
     * and pagination as described on JSON spect: https://jsonapi.org
     * @param path string
     * @param options ResolveUrlConfigType
     * @param method MethodType
     */
    public resolvePath(
        path: string,
        method: MethodType,
        options?: ResolveUrlConfigType,
    ): string {
        const {
            replacements,
            replaceToken = ':',
            include,
            pageSize,
            currentPage,
            sorts,
            filters,
        } = options || {}

        // Divide the url by dot notation
        let params: string[] = []
        if (pageSize && currentPage) {
            params = this.resolvePagination([], pageSize, currentPage)
        }
        params = this.resolveSorts(params, sorts)
        params = this.resolveFilters(params, filters)
        params = this.resolveIncludes(params, include)
        let baseUrl = this.composeUrl(path)
        let [url, type] = this.getUrlType(baseUrl)
        url = this.resolveReplacements(url, replaceToken, replacements)
        if (type && type !== method) {
            throw new Error('The method used for this path is not valid')
        }
        return url + (params.length > 0 ? `?${params.join('&')}` : '')
    }

    private fixRelations(data: any, includes: string[]): any {
        const {included = [], data: recordsData} = data.data as {
            included: any[]
            data: any | any[]
        }
        let fixedData = Array.isArray(recordsData)
            ? [...recordsData]
            : {...recordsData}
        includes.forEach((includeConfig: string) => {
            if (includeConfig.includes(':')) {
                const [, config] = includeConfig.split(':')
                const [entityType, fieldMap] = config?.split('->')
                const [attribute, fieldName] = fieldMap?.split('|')
                if (
                    config &&
                    entityType &&
                    fieldMap &&
                    attribute &&
                    fieldName
                ) {
                    included.forEach(currentRelated => {
                        if (currentRelated.type === entityType) {
                            const relatedId = currentRelated.id
                            if (Array.isArray(fixedData)) {
                            } else if (
                                `${relatedId}` ===
                                `${fixedData.attributes[fieldName]}`
                            ) {
                                fixedData.attributes[attribute] = {
                                    ...fixedData.attributes[attribute],
                                    ...currentRelated,
                                }
                            }
                        }
                    })
                }
            }
        })
        return {...fixedData, extractedRelationships: included}
    }

    /**
     * Call the axios request depending on the given method
     * @param path
     * @param options
     * @returns
     */
    public async doRequest<
        RequestResponseType,
        PayloadType extends Object = {},
    >(
        path: string,
        options?: DoRequestConfig<PayloadType>,
    ): Promise<DoRequestResponseType<RequestResponseType>> {
        const {
            auth,
            debug,
            method = 'get',
            params,
            headers,
            responseType = 'json',
            payload,
            form,
            ...urlConfig
        } = options || {}
        const url = `${this.server}${this.resolvePath(path, method, urlConfig)}`
        let response: AxiosResponse<RequestResponseType | null> = {
            data: null,
            status: 200,
            statusText: 'OK',
            headers: {},
            config: {},
        }
        const mergedHeaders = {
            ...this.headers,
            ...headers,
        }
        try {
            let formData = new FormData()
            if (form === true && payload) {
                mergedHeaders['Content-Type'] = 'multipart/form-data'
                Object.keys(payload).forEach(formKey => {
                    formData.append(formKey, payload[formKey])
                })
            }
            if (method === 'post') {
                response = await axios.post(
                    url,
                    this.payloadTransform
                        ? this.payloadTransform(
                              form == true ? formData : payload,
                          )
                        : form == true
                        ? formData
                        : payload,
                    {
                        params,
                        responseType,
                        headers: mergedHeaders,
                    },
                )
            } else if (method === 'put') {
                response = await axios.put(
                    url,
                    this.payloadTransform
                        ? this.payloadTransform(
                              form == true ? formData : payload,
                          )
                        : form == true
                        ? formData
                        : payload,
                    {
                        params,
                        responseType,
                        headers: mergedHeaders,
                    },
                )
            } else if (method === 'patch') {
                response = await axios.patch(
                    url,
                    this.payloadTransform
                        ? this.payloadTransform(
                              form == true ? formData : payload,
                          )
                        : form == true
                        ? formData
                        : payload,
                    {
                        params,
                        responseType,
                        headers: mergedHeaders,
                    },
                )
            } else if (method === 'delete') {
                response = await axios.delete(url, {
                    params,
                    responseType,
                    headers: mergedHeaders,
                })
            } else {
                response = await axios.get(url, {
                    params,
                    responseType,
                    headers: mergedHeaders,
                })
                if (urlConfig.include) {
                    this.fixRelations(response, urlConfig.include)
                }
            }
            const {data, status} = response
            if (this.onRequestDone) {
                this.onRequestDone(data)
            }
            let processedData = data
            let meta = (data as any)?.meta
            if (this.processData) {
                processedData = this.processData(data)
            }
            return {
                status,
                meta,
                request: {
                    url,
                    server: this.server,
                    method,
                },
                data: processedData as RequestResponseType,
            }
        } catch (err) {
            throw {
                status: err.response.status,
                error: true,
                errorData: err.response,
                request: {
                    url,
                    server: this.server,
                    method,
                },
                info: err,
            }
        }
    }

    /**
     * Sends always a get request
     * @param path
     * @param options
     * @returns
     */
    public async doGet<RequestResponseType, PayloadType extends Object = {}>(
        path: string,
        options?: DoRequestConfig<PayloadType>,
    ) {
        const response = await this.doRequest<RequestResponseType, PayloadType>(
            path,
            {...options, method: 'get'},
        )
        return response
    }

    /**
     * Send always a post request
     * @param path
     * @param options
     * @returns
     */
    public async doPost<RequestResponseType, PayloadType extends Object = {}>(
        path: string,
        options?: DoRequestConfig<PayloadType>,
    ) {
        const response = await this.doRequest<RequestResponseType, PayloadType>(
            path,
            {
                ...options,
                method: 'post',
            },
        )
        return response
    }

    /**
     * Send always a put request
     * @param path
     * @param options
     * @returns
     */
    public async doPut<RequestResponseType, PayloadType extends Object = {}>(
        path: string,
        options?: DoRequestConfig<PayloadType>,
    ) {
        const response = await this.doRequest<RequestResponseType, PayloadType>(
            path,
            {...options, method: 'put'},
        )
        return response
    }

    /**
     * Send always a patch request
     * @param path
     * @param options
     * @returns
     */
    public async doPatch<RequestResponseType, PayloadType extends Object = {}>(
        path: string,
        options?: DoRequestConfig<PayloadType>,
    ) {
        const response = await this.doRequest<RequestResponseType, PayloadType>(
            path,
            {...options, method: 'patch'},
        )
        return response
    }

    /**
     * Send always a delete request
     * @param path
     * @param options
     * @returns
     */
    public async doDelete<RequestResponseType, PayloadType extends Object = {}>(
        path: string,
        options?: DoRequestConfig<PayloadType>,
    ) {
        const response = await this.doRequest<RequestResponseType, PayloadType>(
            path,
            {...options, method: 'delete'},
        )
        return response
    }
}

export default HttpClient
