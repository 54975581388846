import React from 'react'
import { Input as CustomInput } from 'reactstrap'
import { InputPropsType } from '../types'

export interface CheckBoxProps {
    disabled?: boolean
    id: string
    checked?: boolean
    label?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

/**
 * Renders a checkbox input and controls it's behavoiour
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param param0
 * @returns
 */
const CheckBoxField: React.FC<InputPropsType & CheckBoxProps> = ({
    checked,
    disabled,
    id,
    label,
    onChange
}) => {
    return (
        <div>
            <CustomInput
                disabled={disabled}
                type="checkbox"
                id={id}
                onChange={onChange}
                label={label}
                checked={checked}
            />
        </div>
    )
}

export default CheckBoxField
