import React from 'react'
import { IconType } from '@alejandro.devop/bt-types'
import { Icon } from '../../ui'
import { Link } from './styled'

interface IconButtonProps {
    icon: IconType
    id?: string
    className?: string
    onClick?: () => void
    type?: 'default' | 'success' | 'warning' | 'danger' | 'info' | 'primary' | 'dark'
    size?: 'xs' | 'sm' | 'md' | 'lg'
}

const IconButton: React.FC<IconButtonProps> = ({ id, icon, type, size, onClick }) => {
    return (
        <Link
            id={id}
            href="#"
            onClick={(e) => {
                e.preventDefault()
                if (onClick) {
                    onClick()
                }
            }}
            className={`btn btn-${type} btn-icon btn-${size} btn-default`}
        >
            <Icon type={icon} />
        </Link>
    )
}

IconButton.defaultProps = {
    type: 'default',
    size: 'md'
}

export default IconButton
