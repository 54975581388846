import React from 'react'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'

interface GridLoaderProps {
    cols: number
    rows: number
}

/**
 * Renders The loader for the grid while it's feching data
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const GridLoader: React.FC<GridLoaderProps> = ({ cols, rows }) => {
    return (
        <tbody>
            {_.times(rows, (rowId) => (
                <tr key={`skeleton-row-${rowId}`}>
                    {_.times(cols, (key) => (
                        <td key={`skeleton-col-${rowId}-${key}`}>
                            <Skeleton />
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    )
}

GridLoader.defaultProps = {
    cols: 10,
    rows: 10
}

export default GridLoader
