import React, { useState } from 'react'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

type Option = { label: string; value: string | number }

const Dropdown: React.FC<{
    color?: 'primary' | 'default' | 'secondary' | 'success' | 'info' | 'warning' | 'danger'
    direction?: 'down' | 'left' | 'right' | 'up'
    disabled?: boolean
    id?: string
    label: string
    options: Option[]
    onChange?: (o: Option) => void
}> = ({ color, direction, disabled, id, label, onChange, options }) => {
    const [opened, setOpened] = useState<boolean>()
    const toggleValue = () => setOpened(!opened)

    const handleOnChange = (option: Option) => {
        if (onChange) {
            onChange(option)
        }
        toggleValue()
    }

    return (
        <ButtonDropdown
            className="mx-1"
            disabled={disabled}
            isOpen={opened}
            toggle={toggleValue}
            direction={direction}
        >
            <DropdownToggle color={color} disabled={disabled} caret>
                {label}
            </DropdownToggle>
            <DropdownMenu>
                {options.map((option, key) => (
                    <DropdownItem
                        onClick={() => handleOnChange(option)}
                        key={`option-${id}-${key}`}
                    >
                        {option.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </ButtonDropdown>
    )
}

export default Dropdown
