import React, { useState, useEffect } from 'react'
import { useGridHeaders, useBuildFilters } from './hooks'
import GridRender from './grid-renderer'
import { GridContext } from './context'
import { GridConfig, MetaDataType } from './types'
import { useGet } from '@alejandro.devop/react-json-api-client'
// import ColumnOptions from './column-options/ColumnOptions'

interface SmartGridProps {
    /** Function to be used to process headers text */
    headerTextProcessor?: (k: string) => string
    /** Function to be used to proccess body, pagination and filter texts */
    textProcessor?: (t: string, r?: { key: string; value: any }) => string
    onAcctionCalled?: (action: string, rowRecord: any) => void
    /** Configuration for the current grid */
    config: GridConfig<any>
}

let timerForInputValueChange: any = null

/**
 * Renders the Smart grid component, a grid with capabilities to:
 * - Fetch data from a remote origin
 * - Handle filters
 * - Handle sorts
 * - Handle actions
 * - Pagination
 * - Advanced pagination features
 * - Displayed records control
 * Everything controlled (or configured) using JSON files or remote json files (Check the RSmartGrid Component)
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const SmartGrid: React.FC<SmartGridProps> = ({
    config,
    headerTextProcessor,
    onAcctionCalled,
    textProcessor
}) => {
    const {
        displayFiltersFormatter,
        maxPagesToDisplay = 10,
        loadingLabel,
        allowSelect,
        debug,
        headers,
        actions,
        fields,
        id,
        filtersVisible,
        url,
        defaultFilters,
        columnVisibility
    } = config
    const initialFilters = () =>
        filtersConfig.reduce((currentValue, currentItem) => {
            currentValue[currentItem.key] = ''
            return currentValue
        }, {} as any)

    const initialSorts = () =>
        sortKeys.reduce((val, curr) => {
            val[curr] = 0
            return val
        }, {})
    const [colsVisibility, setVisibility] = useState<any>(columnVisibility)
    const [openSettings, setOpenSettings] = useState<boolean>(false)
    const [meta, setMeta] = useState<MetaDataType>({
        totalRecords: 0,
        totalPages: 1,
        currentPage: 1,
        nextPage: 2,
        prevPage: 0,
        recordsPerPage: 10
    })
    const filtersConfig = useBuildFilters(fields)
    const [filterValues, setFilterVaues] = useState(initialFilters())
    const [filtersToApply, setFiltersToApply] = useState<any>({})

    const { currentPage, recordsPerPage } = meta
    const [data, { loading }] = useGet(url, {
        onCompleted: ({
            meta: requestMeta
        }: {
            meta: {
                page?: {
                    'current-page': number
                    from: number
                    'per-page': number
                    'last-page': number
                    to: number
                    total: number
                }
            }
        }) => {
            const { page } = requestMeta || {}

            setMeta({
                ...meta,
                totalRecords: page?.total || meta.totalRecords,
                currentPage: page?.['current-page'] || meta.currentPage,
                totalPages: page?.['last-page'] || meta.totalPages,
                recordsPerPage: page?.['per-page'] || meta.recordsPerPage
            })
        },
        filters: {
            ...defaultFilters,
            ...filtersToApply
        },
        pageSize: recordsPerPage,
        currentPage
    })

    const [selectedRecords] = useState<any[]>([])
    const applyGoToPage = (newPage: number) => setMeta({ ...meta, currentPage: newPage })
    const { headerNames, sortKeys } = useGridHeaders({ fields, columnVisibility: colsVisibility })
    const [viewFilters, setViewFilters] = useState<boolean>(Boolean(filtersVisible))
    const [sorts, setSorts] = useState(initialSorts())

    const toggleSettings = () => setOpenSettings(!openSettings)

    const toggleFilters = () => setViewFilters(!viewFilters)

    const onChangeFilter = (key: string, value: any) => {
        const newValues = {
            ...filterValues,
            [key]: value
        }
        setFilterVaues(newValues)
    }
    const toggleVisibility = (key: string, newValue: boolean) =>
        setVisibility({ ...colsVisibility, [key]: newValue })

    const resetSorts = () => {
        setSorts(initialSorts())
    }

    const handleClearFilters = () => {
        setFilterVaues(initialFilters())
    }

    const handleChangePage = (newPage: number) => {
        setMeta({
            ...meta,
            currentPage: newPage
        })
    }

    const handleSortChange = (key: string) => {
        let currentValue = sorts[key]
        if (currentValue === 0) {
            currentValue = 1
        } else if (currentValue === 1) {
            currentValue = -1
        } else if (currentValue === -1) {
            currentValue = 0
        }
        setSorts({
            ...sorts,
            [key]: currentValue
        })
    }

    const onChangeRecordsPerpage = (newValue: number) => {
        setMeta({
            ...meta,
            currentPage: 1,
            recordsPerPage: newValue
        })
    }

    useEffect(() => {
        if (JSON.stringify(filterValues) !== JSON.stringify(filtersToApply)) {
            clearTimeout(timerForInputValueChange)
            timerForInputValueChange = setTimeout(() => {
                setFiltersToApply(
                    Object.keys(filterValues).reduce((fta, currentKey) => {
                        if (filterValues[currentKey] !== null && filterValues[currentKey] !== '') {
                            fta[currentKey] = filterValues[currentKey]
                        }
                        return fta
                    }, {} as any)
                )
            }, 1000)
        }
        return () => {
            clearTimeout(timerForInputValueChange)
        }
    }, [filterValues, filtersToApply])

    return (
        <GridContext.Provider
            value={{
                onChangeRecordsPerpage,
                // applyRecordsPerPage,
                applyGoToPage,
                selectedRecords,
                headerNames,
                headers,
                maxPagesToDisplay,
                sortNames: sortKeys,
                fields,
                loading,
                displayFiltersFormatter,
                debug,
                onAcctionCalled,
                filterValues,
                sorts,
                columnVisibility: colsVisibility,
                onChangeSort: handleSortChange,
                resetSorts,
                headerTextProcessor,
                clearFilters: handleClearFilters,
                textProcessor,
                actions,
                gridId: id || 'grid',
                data: (data || []) as any,
                loadingLabel,
                allowSelect,
                onChangeFilter,
                viewFilters,
                openSettings,
                toggleFilters,
                filtersConfig,
                meta,
                toggleSettings,
                handleChangePage,
                toggleVisibility
            }}
        >
            <GridRender />
            {/* <ColumnOptions /> */}
        </GridContext.Provider>
    )
}

export default SmartGrid
