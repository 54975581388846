import React from 'react'
import { useMemo, useCallback, useEffect, useState } from 'react'
import { FormConfigType, InputValueType, InputProps, RulesConfigType } from './types'
import _ from 'lodash'

type OutputFormConfigType<T extends any = any> = { [Key in keyof T]: InputProps }

const useGetInputConfig = ({ initialValues, fields, required: fieldsRequired }: any) => {
    const [formValues, setFormValues] = useState({ ...initialValues })
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ target: { name, value } }) => {
            setFormValues({ ...formValues, [name]: value })
        },
        [formValues]
    )
    const keysRequired = fieldsRequired?.split('|')
    const outputFormConfig: OutputFormConfigType = useMemo(
        () =>
            Object.keys(fields).reduce((output, currentKey) => {
                const { className, label, name, placeholder, id, otherProps, onKeyUp } =
                    fields[currentKey]
                const inputConfig = {
                    className,
                    id: id || currentKey,
                    name: name || currentKey,
                    required: keysRequired.includes(currentKey),
                    label,
                    placeholder,
                    onChange: handleChange,
                    value: formValues[currentKey] || '',
                    onKeyUp: (e: React.KeyboardEvent) => (onKeyUp ? onKeyUp(e, formValues) : null),
                    ...otherProps
                }
                output[currentKey] = inputConfig
                return output
            }, {}),
        [formValues]
    )
    return [outputFormConfig, formValues]
}

const validateRequired = (values: { [k: string]: InputValueType }, requiredfields: string[]) => {
    const errors: { [k: string]: any } = {}
    requiredfields.forEach((requiredFieldKey) => {
        if (_.isEmpty(values[requiredFieldKey]) || values[requiredFieldKey] === '') {
            errors[requiredFieldKey] = `${requiredFieldKey} is required`
        }
    })
    return errors
}

const isEmail = (value: string) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)

const validateRules = ({
    rules,
    values,
    errors
}: {
    rules: RulesConfigType
    values: { [k: string]: any }
    errors: { [k: string]: any }
}) => {
    let newErrors = {}
    Object.keys(rules).forEach((fieldName) => {
        const { email } = rules[fieldName] as RulesConfigType
        if (email && !errors[fieldName] && !isEmail(values[fieldName])) {
            newErrors[fieldName] = `${fieldName} is not a valid e-mail`
        }
    })
    return newErrors
}

const useForm = <FormStructureType extends { [k: string]: InputValueType } = {}>(
    formConfig: FormConfigType<FormStructureType>
): [
    OutputFormConfigType<FormStructureType>,
    {
        values: FormStructureType
        isValid: boolean
        formErrors: { [Key in keyof FormStructureType]?: string }
    }
] => {
    const { fields, initialValues, required, rules } = formConfig
    const [isValid, setIsValid] = useState(true)
    const [formErrors, setFormErrors] = useState<{ [Key in keyof FormStructureType]?: string }>({})
    const [outputFormConfig, formValues] = useGetInputConfig({ fields, initialValues, required })

    const checkForm = useCallback(() => {
        let errors = {}
        if (required) {
            const requiredErrors = validateRequired(
                formValues,
                typeof required === 'string' ? required.split('|') : required
            )
            errors = { ...requiredErrors }
        }
        if (rules) {
            const rulesErrors = validateRules({ values: formValues, rules, errors })
            errors = { ...errors, ...rulesErrors }
        }
        console.log('Errors: ', errors)
        if (Object.keys(errors).length > 0) {
            setIsValid(false)
            setFormErrors(errors)
        } else {
            setIsValid(true)
            setFormErrors({})
        }
    }, [formValues, isValid])

    useEffect(() => {
        checkForm()
    }, [formValues])
    return [outputFormConfig, { values: formValues as FormStructureType, isValid, formErrors }]
}

export default useForm
