import React from 'react'
import { CustomInput } from 'reactstrap'
import { InputPropsType } from '../types'

export interface RadioProps {
    disabled?: boolean
    id: string
    checked?: boolean
    label?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioField: React.FC<InputPropsType & RadioProps> = ({
    checked,
    disabled,
    id,
    label,
    onChange
}) => {
    return (
        <div>
            <CustomInput
                disabled={disabled}
                type="radio"
                id={id}
                onChange={onChange}
                label={label}
                checked={checked}
            />
        </div>
    )
}

export default RadioField
