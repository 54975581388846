import React from 'react'

const useKeyboardListener = () => {
    const detectEnter = (callback?: () => void): React.KeyboardEventHandler => {
        const eventHandler: React.KeyboardEventHandler = ({ key }) => {
            if (key === 'Enter' && callback) {
                callback()
            }
        }
        return eventHandler
    }
    return {
        detectEnter
    }
}

export default useKeyboardListener
