import React, { useMemo } from 'react'
import { NormalInputProps, SelectOptionType } from './types'
import ControlRender from './ControlRender'
import { Spinner } from 'reactstrap'
import { useGet } from '@alejandro.devop/react-json-api-client'

/**
 * This component controls the request to fill the select with options from a remote endpoint
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const RemoteInput: React.FC<NormalInputProps> = (props) => {
    let { columnsMapping, options, placeholder, url, requestConfig, ...otherProps } = props
    const { label, value, labelProcessor } = columnsMapping || {}
    const [data, { loading }] = useGet(url || '', requestConfig || {})
    options = useMemo(() => {
        return (Array.isArray(data) ? data : []).map((item: any) => {
            const option: SelectOptionType = { value: null, label: null }
            // TODO: Create an external resolver for this process
            const { id, attributes } = item
            if (value === 'id') {
                option.value = id
            }
            if (label && attributes[label]) {
                option.label = attributes[label]
            }
            if (labelProcessor) {
                option.label = labelProcessor(option.label || '')
            }
            return option
        })
    }, [data, label, value])
    return (
        <div className="w-full flex flex-row flex-1 items-center">
            {loading && (
                <div className="mr-2 z-50 h-full flex justify-center">
                    <Spinner size="sm" />
                </div>
            )}
            <ControlRender
                className="w-full"
                placeholder={placeholder}
                {...otherProps}
                disabled={loading || otherProps.disabled}
                options={options}
            />
        </div>
    )
}

export default RemoteInput
