import React, { useState } from 'react'
import { InputPropsType } from '../types'
import { defaultInputProps } from '../defaults'
import TextField from '../text-field'
import { isEmail } from '../../utils'

export interface EmailProps {
    autoComplete?: any
    validationMessage?: string
}

/**
 * Component which allows you to render a textfield with email validations
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props
 * @returns
 */
const EmailField: React.FC<InputPropsType & EmailProps> = ({
    autoComplete,
    onChange,
    validationMessage,
    value,
    required,
    ...props
}) => {
    const [emailVal, setEmailVal] = useState<string | number | undefined>(value || '')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailVal(e.target.value)
        if (onChange) {
            onChange(e)
        }
    }
    const isFieldValue = (): boolean | undefined => {
        if (required && emailVal === '') {
            return false
        } else if ((emailVal as string).length > 0) {
            return isEmail(emailVal as string)
        }
        return undefined
    }
    const valid = isFieldValue()
    return (
        <TextField
            autoComplete={autoComplete}
            feedBack={valid === false ? validationMessage : undefined}
            invalid={valid === false}
            onChange={handleChange}
            valid={valid}
            value={emailVal}
            required={required}
            {...props}
        />
    )
}

EmailField.defaultProps = {
    ...defaultInputProps,
    validationMessage: 'Invalid E-mail'
}

export default EmailField
