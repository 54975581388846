import styled from 'styled-components'
import { Pagination as PaginationBase } from 'reactstrap'

export const Paragraph = styled.p`
    margin-bottom: 2px;
`
export const Input = styled.input`
    width: 120px;
    text-align: center;
`
export const Pagination = styled(PaginationBase)`
    & ul {
        margin-bottom: 7px;
    }
`
