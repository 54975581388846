import { useContext } from 'react'
import {
    UseGridHeadersConfigType,
    UseGridHeadersReturnType,
    GridContextType,
    GridFieldType
} from './types'
import { GridContext } from './context'
import { FilterOptionsType } from './types'

/**
 * Generates the necessary config for the grid headers
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param config
 * @returns UseGridHeadersReturnType
 */
export const useGridHeaders = (config: UseGridHeadersConfigType): UseGridHeadersReturnType => {
    const { fields, columnVisibility } = config
    const headerNames = Object.keys(fields).filter((key) => {
        const { notReadable } = fields[key]
        if (columnVisibility && columnVisibility[key] === false) {
            return false
        }
        return !notReadable
    })
    const sortKeys = headerNames.filter((key) => {
        const { sort } = fields[key]
        return sort
    })
    return {
        sortKeys,
        headerNames
    }
}
/**
 * Generates the filter structure for the internal components.
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param fields
 * @returns
 */
export const useBuildFilters = (fields: GridFieldType): ({ key: string } & FilterOptionsType)[] => {
    return Object.keys(fields).reduce((configs, currentKey) => {
        const { filterOptions = {}, filter }: GridFieldType = fields[currentKey]
        if (filter) {
            const config = {
                ...filterOptions,
                key: currentKey
            }
            if (!config.type) {
                config.type = 'text'
            }
            configs.push(config)
        }
        return configs
    }, [] as ({ key: string } & FilterOptionsType)[])
}

/**
 * Allows to access the GridContext
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns GridContextType
 */
export const useGridContext = (): GridContextType => {
    const context = useContext(GridContext)
    return context
}
