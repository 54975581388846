import React from 'react'
import { Input, InputGroup, FormFeedback, FormGroup, Label, InputGroupAddon } from 'reactstrap'
import { InputPropsType } from '../types'
import { defaultInputProps } from '../defaults'

/**
 * This component renders a base component for all input text based inputs
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param param0
 * @returns
 */
const TextField: React.FC<InputPropsType> = ({
    addOn,
    addOnType,
    autoComplete,
    bsSize,
    id,
    className,
    feedBack,
    group,
    groupClassName,
    invalid,
    label,
    name,
    onChange,
    onClick,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    placeholder,
    required,
    readOnly,
    type,
    valid,
    value
}) => {
    const inputContent = (
        <Input
            autoComplete={autoComplete || ''}
            bsSize={bsSize}
            id={id || 'input'}
            type={type}
            className={className}
            invalid={invalid}
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onKeyPress={onKeyPress}
            name={name}
            readOnly={readOnly}
            valid={valid}
            value={value}
        />
    )
    return group ? (
        <FormGroup className={groupClassName}>
            {Boolean(label) && (
                <Label className="font-semibold" for={id || 'input'}>
                    {label}
                </Label>
            )}
            <InputGroup>
                {inputContent}
                {addOn && (
                    <InputGroupAddon addonType={addOnType || 'append'}>{addOn}</InputGroupAddon>
                )}
                {feedBack && <FormFeedback tooltip>{feedBack}</FormFeedback>}
            </InputGroup>
        </FormGroup>
    ) : (
        inputContent
    )
}

TextField.defaultProps = {
    ...defaultInputProps,
    bsSize: 'sm'
}

export default TextField
