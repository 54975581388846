import React from 'react'
import { Icon, IconButton } from '@alejandro.devop/bt-components'
import { useGridContext } from '../hooks'

/**
 * Renders the grid options
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns React.FC
 */
const GridOptionsRender: React.FC = () => {
    const { toggleFilters, textProcessor, toggleSettings, viewFilters } = useGridContext()
    const label = viewFilters ? 'closeFilters' : 'advancedSearch'
    return (
        <div className="flex flex-row">
            <div className="flex-1">
                <div className="btn-group">
                    <button className="btn btn-white" onClick={toggleFilters}>
                        <Icon type={viewFilters ? 'times' : 'search'} />
                        <span className="ml-2">{textProcessor ? textProcessor(label) : label}</span>
                    </button>
                </div>
            </div>
            <div>
                <IconButton icon="cogs" onClick={toggleSettings} />
            </div>
        </div>
    )
}

export default GridOptionsRender
