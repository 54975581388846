import React from 'react'
import { Label, FormGroup } from 'reactstrap'
import { InputPropsType } from '../types'
import RemoteInput from './RemoteInput'
import { SelectProps } from './types'
import ControlRender from './ControlRender'

const SelectField: React.FC<InputPropsType & SelectProps> = (props) => {
    const { ajax, disableGroup, id, label, onChange } = props
    const labelRender = (
        <>
            {Boolean(label) && (
                <Label className="font-semibold" for={id}>
                    {label}
                </Label>
            )}
        </>
    )

    const handleChange = (e: any) => {
        if (props?.advanced && onChange) {
            const { value } = e as { value: string }
            onChange({
                target: {
                    name: props.name || '',
                    value
                }
            } as React.ChangeEvent<HTMLInputElement>)
        } else if (onChange) {
            const {
                target: { name, value }
            } = e as React.ChangeEvent<HTMLInputElement>
            onChange({
                target: {
                    name,
                    value
                }
            } as React.ChangeEvent<HTMLInputElement>)
        }
    }

    const content = ajax ? (
        <RemoteInput {...props} onChange={handleChange} />
    ) : (
        <ControlRender {...props} onChange={handleChange} />
    )
    return !disableGroup ? (
        <FormGroup>
            {labelRender}
            {content}
        </FormGroup>
    ) : (
        content
    )
}

SelectField.defaultProps = {
    options: []
}

export default SelectField
