import {useState, useCallback, useEffect} from 'react'
import useGetLazy from './useGetLazy'
import {HttpClientMetaType} from '../types/HttpClient.types'
import usePrevProps from './usePrevProps'
import {
    RequestConfigType,
    DoRequestResponseType,
} from '../types/HttpClient.types'

/**
 * Allows to execute get requests, it call the request as soon as the component is rendered
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param path
 * @param options
 * @returns
 */
const useGet = <
    UrlType extends string = string,
    ResponseType = DoRequestResponseType,
    ParamsType = any,
>(
    path: UrlType,
    options?: RequestConfigType<ParamsType>,
): [
    ResponseType | null | undefined,
    {
        loading: boolean
        refresh: (
            o?: RequestConfigType<ParamsType>,
        ) => Promise<ResponseType | null | undefined>
        meta?: HttpClientMetaType
    },
] => {
    const [requestedData, setData] = useState<ResponseType | null | undefined>()
    const [requestedOnce, setRequestedOnce] = useState<boolean>(false)
    const [calling, setCalling] = useState<boolean>(false)
    const prevProps = usePrevProps({
        currentPage: options?.currentPage,
        pageSize: options?.pageSize,
        filters: options?.filters,
    } as any)
    const [sendRequest, loading, {meta}] = useGetLazy<
        UrlType,
        ResponseType,
        ParamsType
    >(path, {
        ...options,
        defaultLoading: true,
        onCompleted: requestInfo => {
            if (options?.onCompleted) {
                options.onCompleted(requestInfo)
            }
        },
    })

    const refresh = async (overrideOptions?: RequestConfigType<ParamsType>) => {
        const data = await sendRequest(overrideOptions)
        setData(data)
        return data
    }

    const fetchData = useCallback(
        async (overrideOptions?: RequestConfigType<ParamsType>) => {
            const response = await sendRequest(overrideOptions)
            setData(response)
            setRequestedOnce(true)
        },

        [requestedOnce, calling],
    )
    useEffect(() => {
        if (!requestedOnce && !calling) {
            setCalling(true)
            fetchData()
        }
    }, [requestedOnce, calling])

    useEffect(() => {
        if (requestedOnce && !loading) {
            if (
                options?.pageSize !== prevProps.pageSize ||
                options?.currentPage !== prevProps.currentPage ||
                JSON.stringify(options?.filters) !==
                    JSON.stringify(prevProps.filters)
            ) {
                fetchData({
                    pageSize: options?.pageSize,
                    currentPage: options?.currentPage,
                    filters: options?.filters,
                })
            }
        }
    }, [
        requestedOnce,
        options?.currentPage,
        options?.pageSize,
        prevProps.currentPage,
        prevProps.pageSize,
        prevProps.filters,
        options?.filters,
    ])

    return [requestedData, {loading, refresh, meta}]
}

export default useGet
