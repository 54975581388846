import React from 'react'
import { NormalInputProps } from './types'
import { CustomInput } from 'reactstrap'
import Select, { OptionTypeBase } from 'react-select'

/**
 * This component renders the dropdown depending if it's a Advanced (Select2) or normal
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param param0
 * @returns
 */
const ControlRender: React.FC<NormalInputProps> = ({
    advanced,
    disabled,
    className,
    id,
    multiple,
    name,
    onChange,
    placeholder,
    value,
    options,
    empty
}) => {
    return advanced ? (
        <Select
            id={id}
            className={className}
            options={options}
            inputProps={{ autoComplete: 'random-string', autoFill: 'off' }}
            onChange={onChange}
            isMulti={multiple}
            name={name}
            placeholder={placeholder}
            value={value as OptionTypeBase}
        />
    ) : (
        <CustomInput
            disabled={disabled}
            id={id || 'select-field'}
            name={name}
            type="select"
            onChange={onChange}
            value={value}
        >
            {Boolean(empty) && <option value="">{empty}</option>}
            {options &&
                Array.isArray(options) &&
                options.map((item, key) => (
                    <option key={`${key}-${id}-option`} value={item.value as string}>
                        {item.label}
                    </option>
                ))}
        </CustomInput>
    )
}

export default ControlRender
