import React, { useState } from 'react'
import { useGridContext } from '../hooks'
import GridPaginationDisplay from './GridPaginationDisplay'

/**
 * Controller for the pagination, it provides all the logic for the pagination renderer
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns React.FC
 */
const GridPaginationRender: React.FC<{
    disableGoTo?: boolean
    disableStepper?: boolean
    disablePager?: boolean
}> = ({ disableGoTo, disableStepper, disablePager }) => {
    const { meta, maxPagesToDisplay, handleChangePage, applyGoToPage, onChangeRecordsPerpage } =
        useGridContext()
    const { totalPages, currentPage, recordsPerPage } = meta
    const [activePage, setPage] = useState<number>(currentPage)
    const [recordsPerPageControl, setRecordsPerpage] = useState<number>(recordsPerPage)

    const shouldSkipPages = maxPagesToDisplay && totalPages > maxPagesToDisplay

    // ToDo: Move this to a prop
    const limit = 10
    /**
     * The following logic allows to control the displacement for the pagination cursor
     * @note Can be improved
     * Begin:
     */
    let middlePoint = Math.ceil(limit / 2) + 1
    let beginPoint = 0
    let endPoint = limit
    let rightIncrement = 0
    let leftIncrement = 0

    if (activePage > middlePoint) {
        const diff = activePage - middlePoint
        if (activePage <= totalPages - (limit - middlePoint)) {
            leftIncrement = diff
        } else {
            leftIncrement = totalPages - limit
        }
        rightIncrement = diff
    }
    const pagesMap = Array.from({ length: totalPages }, (_, i) => i + 1)
    const pagesToRender = pagesMap.slice(beginPoint + leftIncrement, endPoint + rightIncrement)

    /**
     * End
     */

    /**
     * Handles the increment when the user presses next
     */
    const handleNext = (event: any) => {
        event.preventDefault()
        let nextValue = activePage
        if (activePage < totalPages) {
            nextValue = activePage + 1
            setPage(nextValue)
        }
        if (nextValue !== activePage) {
            handleChangePage(nextValue)
        }
    }

    /**
     * Handles the decrement when the user presses substract
     */
    const handlePrev = (event: any) => {
        event.preventDefault()
        let nextValue = activePage
        if (activePage > 1) {
            nextValue = activePage - 1
            setPage(nextValue)
        }
        if (nextValue !== activePage) {
            handleChangePage(nextValue)
        }
    }

    /**
     * Allows to go the first page
     * @note always will be 1
     * @param event
     */
    const goToFirst = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setPage(1)
        if (activePage !== 1) {
            handleChangePage(1)
        }
    }

    /**
     * Allows to go the last page (provided by the api or the smart grid)
     * @param event
     */
    const goToLast = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setPage(totalPages)
        if (activePage !== totalPages) {
            handleChangePage(totalPages)
        }
    }

    /**
     * Handles page change when the user presses the displayed page numbers
     * @param event
     * @param num
     */
    const handleClickOnNumber = (event: React.MouseEvent<HTMLElement>, num: number) => {
        event.preventDefault()
        setPage(num)
        handleChangePage(num)
    }

    /**
     * Handles the change for the records per page value, but it's only used for the display value on the input
     */
    const handleChangeRecordsPerpage = (newValue: number) => {
        setRecordsPerpage(newValue)
    }

    /**
     * Sends the displayed value to the official records per page state, it automatically trigers a new
     * server request.
     */
    const onApplyRecordsPerPage = () => {
        setPage(1)
        onChangeRecordsPerpage(recordsPerPageControl)
    }

    /**
     * Sends the displayed value to the official currentPage state, it automatically trigers a new
     * server request
     * @param n new value for the current page state.
     */
    const handleApplyGoTo = (n: number) => {
        setPage(n)
        applyGoToPage(n)
    }

    return (
        <GridPaginationDisplay
            disableGoTo={disableGoTo}
            disableStepper={disableStepper}
            disablePager={disablePager}
            handleApplyGoTo={handleApplyGoTo}
            activePage={activePage}
            onChangeRecordsPerPage={handleChangeRecordsPerpage}
            recordsPerPageControl={recordsPerPageControl}
            onApplyRecordsPerPage={onApplyRecordsPerPage}
            goToFirst={goToFirst}
            goToLast={goToLast}
            handleClickOnNumber={handleClickOnNumber}
            handleNext={handleNext}
            handlePrev={handlePrev}
            limit={limit}
            middlePoint={middlePoint}
            pagesToRender={pagesToRender}
            shouldSkipPages={shouldSkipPages}
        />
    )
}

export default GridPaginationRender
