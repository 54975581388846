import React from 'react'
import { Button as ButtonBase } from 'reactstrap'
import { ButtonProps as Props } from './types'
import { Icon } from '../../ui'

/**
 * Renders a bootstrap button
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
const Button: React.FC<Props> = ({
    children,
    className,
    color,
    disabled,
    icon,
    iconSize,
    onClick,
    size,
    variant
}) => {
    return (
        <ButtonBase
            color={color}
            className={`btn btn-${variant} ${className}`}
            disabled={disabled}
            onClick={onClick}
            size={size}
        >
            {children}
            {Boolean(icon) && <Icon className="ml-2" type={icon || 'circle'} size={iconSize} />}
        </ButtonBase>
    )
}

Button.defaultProps = {
    color: undefined,
    size: 'sm',
    type: 'button',
    iconSize: 'lg',
    variant: 'default'
}

export default Button
