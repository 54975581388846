import React from 'react'
import { ColWrapper, Input } from './styled'

/**
 * Renders the control for row selection
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns React.FC
 */
const ColSelectControl: React.FC = () => {
    return (
        <ColWrapper>
            <Input className="form-check-input" type="checkbox" id="checkbox1" />
        </ColWrapper>
    )
}

export default ColSelectControl
